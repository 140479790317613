<!-- Invoice Page - Start -->
<!-- {{ info.freeSpace }} -->
<!-- <div class="print-wrapper">
  <div id="hidden"></div>
</div> -->

<div id="invoice-page"></div>

<div class="hide">
  <!-- Header - Start -->
  <header
    class="grid pb-3"
    id="header"
  ></header>

  <!-- Invoice & Buyer Info - Start -->
  <!-- <div
    class="grid"
    id="customer-info"
  >
    <div class="col-3">
      <div class="font-medium">Bill to</div>
      <div class="font-medium text-neutral-60 mb-1">العميل</div>
      <div>مؤسسة عبدالرحمن هلال للمقاولات والترقيع شركة شخص واحد</div>
      <div>
        3575 Al Feidh, Irqah Unit 123456789011 No Kingdom of Saudi Arabia
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Buyer Tax Number</div>
          <div class="font-medium text-neutral-60 mb-1">
            رقم السجل الضريبي للعميل
          </div>
          <div>264867547786453</div>
        </div>
        <div>
          <div class="font-medium">Buyer ID: CR</div>
          <div class="font-medium text-neutral-60 mb-1">
            معرّف العميل: سجل تجاري
          </div>
          <div>1234567890</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Invoice Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم الفاتورة</div>
          <div>{{ invoice.id }}</div>
        </div>
        <div>
          <div class="font-medium">Invoice Issue Date</div>
          <div class="font-medium text-neutral-60 mb-1">
            تاريخ إصدار الفاتورة
          </div>
          <div>23 Dec 2024, 07:35:59 AM</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2 text-right">
        <div>
          <div class="font-medium">Reference No.</div>
          <div class="font-medium text-neutral-60 mb-1">رقم مرجعي</div>
          <div>11223344</div>
        </div>
        <div>
          <div class="font-medium">Supply Date</div>
          <div class="font-medium text-neutral-60 mb-1">تاريخ التسليم</div>
          <div>23 Dec 2024</div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Price Highlight - Start -->
  <div class="text-right pt-2 border-top-1 border-neutral-20">
    <div class="font-medium">Total Due</div>
    <div class="font-medium text-neutral-60">إجمالي المستحق</div>
    <div class="flex gap-1 align-items-center justify-content-end">
      <div class="line-height-1">
        <div class="rw__fs-9">SAR</div>
        <div class="rw__fs-11 text-neutral-60">ر.س</div>
      </div>
      <div class="rw__fs-5">2300.00</div>
    </div>
  </div>

  <!-- Invoice Item Line Table - Start-->
  <div class="pb-2 pt-3">
    <table class="print-table print-border-table print-item-table">
      <thead>
        <tr>
          <th class="text-left">
            <div class="font-medium text-neutral-60">#</div>
          </th>
          <th>
            <div class="font-medium">Item / Description</div>
            <div class="font-medium text-neutral-60">الوصف / المنتج</div>
          </th>
          <th>
            <div class="font-medium">Price</div>
            <div class="font-medium text-neutral-60">سعر الوحدة</div>
          </th>
          <th>
            <div class="font-medium">Quantity</div>
            <div class="font-medium text-neutral-60">الكمية</div>
          </th>
          <th>
            <div class="font-medium">Discount</div>
            <div class="font-medium text-neutral-60">خصم</div>
          </th>
          <th>
            <div class="font-medium">Taxable Amount</div>
            <div class="font-medium text-neutral-60">المبلغ الخاضع للضريبة</div>
          </th>
          <th>
            <div class="font-medium">VAT</div>
            <div class="font-medium text-neutral-60">القيمة المضافة</div>
          </th>
          <th>
            <div class="font-medium">Amount</div>
            <div class="font-medium text-neutral-60">المجموع</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left">
            <div class="font-medium text-neutral-60">1</div>
          </td>
          <td class="text-neutral-60">
            <div class="font-semibold">
              <div>Basic Plan (1 year)</div>
              <div>اشتراك باقة أساسية (سنة)</div>
            </div>
            <div>1 Jan 2023-31 Dec 2024</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>x3</div>
          </td>
          <td>
            <div>-4000</div>
            <div class="text-neutral-60">60%</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>300</div>
            <div class="text-neutral-60">15%</div>
          </td>
          <td>
            <div>2300</div>
          </td>
        </tr>
        <tr>
          <td class="text-left">
            <div class="font-medium text-neutral-60">1</div>
          </td>
          <td class="text-neutral-60">
            <div class="font-semibold">
              <div>Basic Plan (1 year)</div>
              <div>اشتراك باقة أساسية (سنة)</div>
            </div>
            <div>1 Jan 2023-31 Dec 2024</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>x3</div>
          </td>
          <td>
            <div>-4000</div>
            <div class="text-neutral-60">60%</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>300</div>
            <div class="text-neutral-60">15%</div>
          </td>
          <td>
            <div>2300</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Notes & Calculation Summary - Start -->
  <div class="grid">
    <div class="col-6">
      <div class="font-medium">Notes</div>
      <div class="font-medium text-neutral-60 mb-1">ملاحظات</div>
      <div>-</div>
    </div>
    <div class="col-6">
      <table class="print-table print-item-table">
        <tbody>
          <tr>
            <td>
              <div class="font-medium">Subtotal</div>
              <div class="font-medium text-neutral-60">
                الإجمالي غير شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>6000</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Discount</div>
              <div class="font-medium text-neutral-60">إجمالي الخصم</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>-4000</div>
              <div class="text-neutral-60">60%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Taxable Amount</div>
              <div class="font-medium text-neutral-60">
                إجمالي المبلغ الخاضع للضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>2000</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total VAT</div>
              <div class="font-medium text-neutral-60">
                إجمالي ضريبة القيمة المضافة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>300</div>
              <div class="text-neutral-60">15%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total</div>
              <div class="font-medium text-neutral-60">
                الإجمالي شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-medium">2300</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9">
        <img
          src="/assets/images/invoice-pdf-qr.svg"
          alt=""
        />
        <div class="mt-2">
          This QR code is encoded as per ZATCA's invoicing requirements
        </div>
        <div>
          رمز الإستجابة السريعة مشفّر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
          للفوترة الإلكترونية
        </div>
      </div>
      <div class="col-3">
        <div class="text-right">Page 1 of 3 - INV-000100</div>
      </div>
    </div>
  </footer>
</div>
<!-- Invoice Page - End -->

<!-- <div class="py-6"></div> -->

<!-- Bank Transfer Information - Start -->
<div
  class="print-wrapper page-break body"
  id="bank-transfer"
>
  @if (invoice?.zatcaStatus !== 'Completed') {
    <div class="watermark">
      <div>DRAFT INVOICE</div>
      <div>فاتـــــــــــــــــــــورة مسودّة</div>
    </div>
  }
  <!-- Header - Start -->
  <header class="grid pb-3">
    <div class="col-5">
      <div class="fs-22 font-semibold">Tax Invoice</div>
      <div class="rw__fs-7 font-normal text-neutral-60">فاتورة ضريبية</div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-emblem-logo.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-neutral-60 text-right">
      <div>شركة رواء التقنية لتقنية المعلومات</div>
      <div>٣٢٥٩، انس بن مالك، ٨٣٨٤ حي الملقا، ١٣٥٢٢ الرياض</div>
      <div>المملكة العربية السعودية</div>
      <div>CR: 31018820003</div>
      <div>TRN: 310188259700003</div>
    </div>
  </header>
  <!-- Header - End -->

  <!-- Page Title - Start -->
  <div
    class="flex justify-content-between rw__fs-11 font-bold pb-3 border-neutral-20 border-bottom-1"
  >
    <div>Bank Transfer Information</div>
    <div>معلومات الحوالات البنكية</div>
  </div>
  <!-- Page Title - End -->

  <!-- Beneficiary Details - Start -->
  <div class="py-3">
    <div class="flex justify-content-between font-medium">
      <div>Beneficiary Name</div>
      <div>إسم المستفيد</div>
    </div>
    <div class="flex justify-content-between mt-1">
      <div>Rewaa Technology Limited Liability Company</div>
      <div>شركة رواء لتقنية المعلومات المحدودة</div>
    </div>
  </div>
  <!-- Beneficiary Details - End -->

  <!-- Bank Details - Start -->
  <div>
    <table class="print-table print-border-table bank-table">
      <thead>
        <tr>
          <th class="text-left"><div>Bank</div></th>
          <th>
            <div class="flex justify-content-between">
              <div>IBAN</div>
              <div>آيبان</div>
            </div>
          </th>
          <th>
            <div>البنك</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left"><div>Al Rajhi Bank</div></td>
          <td>
            <div>SA38 8000 0504 6080 1025 5470</div>
          </td>
          <td>
            <div>مصرف الراجحي</div>
          </td>
        </tr>
        <tr>
          <td class="text-left"><div>Riyad Bank</div></td>
          <td>
            <div>SA74 2000 0002 4116 3936 9940</div>
          </td>
          <td>
            <div>بنك الرياض</div>
          </td>
        </tr>
        <tr>
          <td class="text-left"><div>Saudi National Bank (SNB)</div></td>
          <td>
            <div>SA37 1000 0019 5000 0006 6907</div>
          </td>
          <td>
            <div>البنك الأهلي السعودي</div>
          </td>
        </tr>
        <tr>
          <td class="text-left"><div>SAIB Bank</div></td>
          <td>
            <div>SA85 6500 0000 1016 9293 9001</div>
          </td>
          <td>
            <div>البنك السعودي للإستثمار</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Bank Details - End -->

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9"></div>
      <div class="col-3">
        <div class="text-right">
          Page {{ info.pages + 1 }} of {{ info.pages + 2 }} - INV-000100
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer - End -->
</div>
<!-- Bank Transfer Information - End -->

<!-- <div class="py-6"></div> -->

<!-- Terms & Conditions - Start -->
<div
  class="print-wrapper page-break"
  id="T&C"
>
  <!-- Header - Start -->
  <header class="grid pb-3">
    <div class="col-5">
      <div class="fs-22 font-semibold">Tax Invoice</div>
      <div class="rw__fs-7 font-normal text-neutral-60">فاتورة ضريبية</div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-emblem-logo.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-neutral-60 text-right">
      <div>شركة رواء التقنية لتقنية المعلومات</div>
      <div>٣٢٥٩، انس بن مالك، ٨٣٨٤ حي الملقا، ١٣٥٢٢ الرياض</div>
      <div>المملكة العربية السعودية</div>
      <div>CR: 31018820003</div>
      <div>TRN: 310188259700003</div>
    </div>
  </header>
  <!-- Header - End -->

  <!-- Page Title - Start -->
  <div class="grid pb-2 border-neutral-20 border-bottom-1 align-items-end">
    <div class="col-5">
      <div class="rw__fs-11 font-bold">
        Terms &amp; Conditions, and Privacy Policy
      </div>
      <div class="text-neutral-60">
        Scan QR to go through all of the terms &amp; conditions
      </div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-terms-qr.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-right">
      <div class="rw__fs-11 font-bold">الشروط والأحكام وسياسة الخصوصية</div>
      <div class="text-neutral-60">
        قم بمسح الرمز للإطلاع على جميع الشروط والأحكام
      </div>
    </div>
  </div>
  <!-- Page Title - End -->

  <!-- Terms Points - Start -->
  <div class="pt-2">
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Key Terms and Conditions</div>
        <div>
          The Terms and Conditions constitute a legally binding agreement
          between the customer and Rewaa for Information Technology. By using,
          subscribing to, or purchasing our services or products, you
          acknowledge your acceptance of our Terms & Conditions, and all
          policies published on our website, including the Privacy Policy. If
          you do not agree with these terms or any other Rewaa policies, you may
          submit a request for a refund of your subscription fee during the
          trial period (14 calendar days from the invoice date). Continuing to
          use Rewaa's services or products after the trial period is considered
          an explicit acceptance of these Terms and Conditions. Some of these
          terms include, but are not limited to:
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">أبرز الشروط والأحكام</div>
        <div>
          لشروط والأحكام تمثل ملزمة قانونياً بين العميل وشركة رِواء التقنية
          لتقنية المعلومات. استخدامك لخدماتنا أو منتجاتنا أو الاشتراك فيها هو
          إقرار بقبولك هذه الشروط وكافة السياسات المنشورة على موقعنا، بما في ذلك
          سياسة الخصوصية. نوصيكم بمراجعتها للتأكد من أنها تتوافق مع توقعاتكم،
          يمكنكم الاطلاع عليها من خلال رمز الاستجابة السريع المرفق أو موقعنا
          الرسمي. في حال عدم موافقتكم على أي من الشروط أو السياسات، يمكنكم رفع
          طلب إرجاع لمبلغ الاشتراك خلال فترة التجربة (14 يومًا تقويميا من تاريخ
          فاتورة الشراء). استمراركم في استخدام خدمات أو منتجات رواء بعد انتهاء
          فترة التجربة يُعد موافقة صريحة وقبولًا ضمنيًا لهذه الشروط والأحكام.
          ونورد بعضها هنا على سبيل المثال لا الحصر:
        </div>
      </div>
    </div>
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Software Operation Requirements</div>
        <div>
          The customer must ensure a suitable environment for operating the
          software, including providing the necessary hardware and high-speed
          internet. Click here for more details.
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">متطلبات تشغيل الخدمة</div>
        <div>
          يجب على العميل تأمين البيئة المناسبة لتشغيل البرنامج، بما في ذلك توفير
          الأجهزة اللازمة والإنترنت عالي السرعة. لمزيد من التفاصيل، يرجى الضغط
          على هذا الرابط.
        </div>
      </div>
    </div>
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Subscription Start Date</div>
        <div>
          The subscription begins from the date of the purchase invoice
          regardless of the date the hardware or/and any other devices are
          received. The subscription continues according to the package
          selected, and Rewaa is not liable for any delay caused by the customer
          in operating their business or operations.
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">تاريخ بدء الاشتراك</div>
        <div>
          يبدأ تاريخ الاشتراك اعتباراً من تاريخ إصدار فاتورة شراء، بغض النظر عن
          تاريخ استلام الأجهزة أو البدء بتجربة منصة رواء. ويستمر الاشتراك وفقًا
          للباقة التي تم الاشتراك بها، ولا تتحمل شركة رواء أي مسؤولية في حال
          تأخر العميل في تشغيل تجارته أو عملياته.
        </div>
      </div>
    </div>
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Device Warranty</div>
        <div>
          Rewaa devices are covered by a two-year (2) warranty from the date of
          the purchase invoice.
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">ضمان الأجهزة</div>
        <div>
          ضمان أجهزة رِواء لمدة سنتين (2) من تاريخ فاتورة الشراء، ويشمل إصلاح أو
          استبدال الجهاز المتضرر بسبب العيوب التصنيعية بدون تكاليف إضافية.
        </div>
      </div>
    </div>
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Refund Policy</div>
        <div>
          Rewaa is committed to refunding any amounts paid for products or
          services within the 14-calendar-day trial period from the date of the
          purchase invoice. The refund request will be processed and the amount
          refunded within 30 calendar days from the date of approval of the
          return request or receipt of the devices with their accessories,
          including packaging.
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">سياسة الإرجاع</div>
        <div>
          تلتزم شركة رِواء التقنية باسترداد أي مبلغ مدفوع على المنتجات أو
          الخدمات خلال فترة التجربة التي تمتد لـ 14 يوما تقويميا من تاريخ إصدار
          فاتورة الشراء. يتم معالجة طلب الإرجاع وإعادة المبلغ خلال 30 يوما
          تقويميا من تاريخ اعتماد طلب الإرجاع أو استلام الأجهزة بكامل ملحقاتها،
          بما في ذلك الكرتون.
        </div>
      </div>
    </div>
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Technical Support</div>
        <div>
          Rewaa provides remote technical support exclusively through official
          communication channels, without any obligation to perform on-site
          visits or operational tasks on behalf of the client. Technical support
          is available 24/7 to assist you via live chat within the platform,
          toll-free number: 8001244020, or email: info&#64;rewaatech.com
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">الدعم الفني</div>
        <div>
          تقدم شركة رِواء الدعم الفني عن بُعد عبر قنوات التواصل الرسمية فقط، دون
          أي التزام بإجراء الزيارات الميدانية أو الأعمال التشغيلية نيابة عن
          العميل. الدعم الفني متاح لمساعدتك على مدار الساعة 24/7 عبر الدردشة
          الحية من داخل المنصة أو الرقم الموحد 8001244020 أو البريد الإلكتروني
          info&#64;rewaatech.com
        </div>
      </div>
    </div>
  </div>
  <!-- Terms Points - End -->

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9"></div>
      <div class="col-3">
        <div class="text-right">
          {{ info.pages + 2 }} of {{ info.pages + 2 }} - INV-000100
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer - End -->
</div>
<!-- Terms & Conditions - End -->

<!-- <div class="py-6"></div> -->

<div id="credit-page"></div>

<!-- Credit Page - Start -->
<div class="hide">
  <!-- Header - Start -->
  <header class="grid pb-3">
    <div class="col-5">
      <div class="fs-22 font-semibold">Credit Note</div>
      <div class="rw__fs-7 font-normal text-neutral-60">إشعار دائن</div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-emblem-logo.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-neutral-60 text-right">
      <div>شركة رواء التقنية لتقنية المعلومات</div>
      <div>٣٢٥٩، انس بن مالك، ٨٣٨٤ حي الملقا، ١٣٥٢٢ الرياض</div>
      <div>المملكة العربية السعودية</div>
      <div>CR: 31018820003</div>
      <div>TRN: 310188259700003</div>
    </div>
  </header>

  <!-- Invoice & Buyer Info - Start -->
  <div class="grid">
    <div class="col-3">
      <div class="font-medium">Bill to</div>
      <div class="font-medium text-neutral-60 mb-1">العميل</div>
      <div>مؤسسة عبدالرحمن هلال للمقاولات والترقيع شركة شخص واحد</div>
      <div>
        3575 Al Feidh, Irqah Unit 123456789011 No Kingdom of Saudi Arabia
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Buyer Tax Number</div>
          <div class="font-medium text-neutral-60 mb-1">
            رقم السجل الضريبي للعميل
          </div>
          <div>264867547786453</div>
        </div>
        <div>
          <div class="font-medium">Buyer ID: CR</div>
          <div class="font-medium text-neutral-60 mb-1">
            معرّف العميل: سجل تجاري
          </div>
          <div>1234567890</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Credit Note Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم إشعار دائن</div>
          <div>CN-000101</div>
        </div>
        <div>
          <div class="font-medium">Credit Note Issue Date</div>
          <div class="font-medium text-neutral-60 mb-1">
            تاريخ إصدار إشعار دائن
          </div>
          <div>23 Dec 2024, 07:35:59 AM</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2 text-right">
        <div>
          <div class="font-medium">Invoice Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم الفاتورة</div>
          <div>INV-000100</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Highlight - Start -->
  <div class="text-right pt-2 border-top-1 border-neutral-20">
    <div class="font-medium">Total Credit Note Amount</div>
    <div class="font-medium text-neutral-60">إجمالي قيمة إشعار الدائن</div>
    <div class="flex gap-1 align-items-center justify-content-end">
      <div class="line-height-1">
        <div class="rw__fs-9">SAR</div>
        <div class="rw__fs-11 text-neutral-60">ر.س</div>
      </div>
      <div class="rw__fs-5">2300.00</div>
    </div>
  </div>

  <!-- Invoice Item Line Table - Start-->
  <div class="pb-2 pt-3">
    <table class="print-table print-border-table print-item-table">
      <thead>
        <tr>
          <th class="text-left">
            <div class="font-medium text-neutral-60">#</div>
          </th>
          <th>
            <div class="font-medium">Item / Description</div>
            <div class="font-medium text-neutral-60">الوصف / المنتج</div>
          </th>
          <th>
            <div class="font-medium">Price</div>
            <div class="font-medium text-neutral-60">سعر الوحدة</div>
          </th>
          <th>
            <div class="font-medium">Quantity</div>
            <div class="font-medium text-neutral-60">الكمية</div>
          </th>
          <th>
            <div class="font-medium">Discount</div>
            <div class="font-medium text-neutral-60">خصم</div>
          </th>
          <th>
            <div class="font-medium">Taxable Amount</div>
            <div class="font-medium text-neutral-60">المبلغ الخاضع للضريبة</div>
          </th>
          <th>
            <div class="font-medium">VAT</div>
            <div class="font-medium text-neutral-60">القيمة المضافة</div>
          </th>
          <th>
            <div class="font-medium">Amount</div>
            <div class="font-medium text-neutral-60">المجموع</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left">
            <div class="font-medium text-neutral-60">1</div>
          </td>
          <td class="text-neutral-60">
            <div class="font-semibold">
              <div>Basic Plan (1 year)</div>
              <div>اشتراك باقة أساسية (سنة)</div>
            </div>
            <div>1 Jan 2023-31 Dec 2024</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>x3</div>
          </td>
          <td>
            <div>-4000</div>
            <div class="text-neutral-60">60%</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>300</div>
            <div class="text-neutral-60">15%</div>
          </td>
          <td>
            <div>2300</div>
          </td>
        </tr>
        <tr>
          <td class="text-left">
            <div class="font-medium text-neutral-60">1</div>
          </td>
          <td class="text-neutral-60">
            <div class="font-semibold">
              <div>Basic Plan (1 year)</div>
              <div>اشتراك باقة أساسية (سنة)</div>
            </div>
            <div>1 Jan 2023-31 Dec 2024</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>x3</div>
          </td>
          <td>
            <div>-4000</div>
            <div class="text-neutral-60">60%</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>300</div>
            <div class="text-neutral-60">15%</div>
          </td>
          <td>
            <div>2300</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Notes & Calculation Summary - Start -->
  <div class="grid">
    <div class="col-6">
      <div class="font-medium">Notes</div>
      <div class="font-medium text-neutral-60 mb-1">ملاحظات</div>
      <div>-</div>
    </div>
    <div class="col-6">
      <table class="print-table print-item-table">
        <tbody>
          <tr>
            <td>
              <div class="font-medium">Subtotal</div>
              <div class="font-medium text-neutral-60">
                الإجمالي غير شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>6000</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Discount</div>
              <div class="font-medium text-neutral-60">إجمالي الخصم</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>-4000</div>
              <div class="text-neutral-60">60%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Taxable Amount</div>
              <div class="font-medium text-neutral-60">
                إجمالي المبلغ الخاضع للضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>2000</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total VAT</div>
              <div class="font-medium text-neutral-60">
                إجمالي ضريبة القيمة المضافة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>300</div>
              <div class="text-neutral-60">15%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total</div>
              <div class="font-medium text-neutral-60">
                الإجمالي شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-medium">2300</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9">
        <img
          src="/assets/images/invoice-pdf-qr.svg"
          alt=""
        />
        <div class="mt-2">
          This QR code is encoded as per ZATCA's invoicing requirements
        </div>
        <div>
          رمز الإستجابة السريعة مشفّر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
          للفوترة الإلكترونية
        </div>
      </div>
      <div class="col-3">
        <div class="text-right">Page 1 of 1 - CN-000101</div>
      </div>
    </div>
  </footer>
</div>

<!-- Credit Page - End -->

<!-- <div class="py-6"></div> -->

<div id="payment-summary-page"></div>

<!-- Payment Page - Start -->
<div class="hide">
  <!-- Header - Start -->
  <header class="grid pb-3">
    <div class="col-5">
      <div class="fs-22 font-semibold">Payment Summary</div>
      <div class="rw__fs-7 font-normal text-neutral-60">ملخّص المدفوعات</div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-emblem-logo.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-neutral-60 text-right">
      <div>شركة رواء التقنية لتقنية المعلومات</div>
      <div>٣٢٥٩، انس بن مالك، ٨٣٨٤ حي الملقا، ١٣٥٢٢ الرياض</div>
      <div>المملكة العربية السعودية</div>
      <div>CR: 31018820003</div>
      <div>TRN: 310188259700003</div>
    </div>
  </header>
  <!-- Header - End -->

  <!-- Invoice & Buyer Info - Start -->
  <div class="grid">
    <div class="col-3">
      <div class="font-medium">Customer</div>
      <div class="font-medium text-neutral-60 mb-1">العميل</div>
      <div>مؤسسة عبدالرحمن هلال للمقاولات والترقيع شركة شخص واحد</div>
      <div>
        3575 Al Feidh, Irqah Unit 123456789011 No Kingdom of Saudi Arabia
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Buyer Tax Number</div>
          <div class="font-medium text-neutral-60 mb-1">
            رقم السجل الضريبي للعميل
          </div>
          <div>264867547786453</div>
        </div>
        <div>
          <div class="font-medium">Buyer ID: CR</div>
          <div class="font-medium text-neutral-60 mb-1">
            معرّف العميل: سجل تجاري
          </div>
          <div>1234567890</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Invoice Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم الفاتورة</div>
          <div>INV-000100</div>
        </div>
      </div>
    </div>
    <div class="col-3 text-right">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Last Updated</div>
          <div class="font-medium text-neutral-60 mb-1">تاريخ آخر تحديث</div>
          <div>23 Dec 2024, 07:35:59 AM</div>
        </div>
      </div>
    </div>
  </div>
  <!-- Invoice & Buyer Info - End -->

  <!-- Invoice Item Line Table - Start-->
  <div class="pb-2 pt-6">
    <table class="print-table print-border-table payment-table">
      <thead>
        <tr>
          <th class="text-left">
            <div class="font-medium">Transaction Date</div>
            <div class="font-medium text-neutral-60">تاريخ العملية</div>
          </th>
          <th class="text-left">
            <div class="font-medium">Payment Method</div>
            <div class="font-medium text-neutral-60">طريقة الدفع</div>
          </th>
          <th class="text-left">
            <div class="font-medium">Reference No.</div>
            <div class="font-medium text-neutral-60">الرقم المرجعي</div>
          </th>
          <th>
            <div class="font-medium">Amount Paid</div>
            <div class="font-medium text-neutral-60">المبلغ المدفوع</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left">
            <div class="font-semibold text-neutral-60">23 Dec 2024</div>
          </td>
          <td class="text-left">
            <div>Bank Transfer</div>
            <div class="text-neutral-60">Al Rajhi Bank</div>
          </td>
          <td class="text-left">
            <div>txn_169xRMUPwwaVhEB1U</div>
          </td>
          <td>
            <div class="font-semibold">800</div>
          </td>
        </tr>
        <tr>
          <td class="text-left">
            <div class="font-semibold text-neutral-60">23 Dec 2024</div>
          </td>
          <td class="text-left">
            <div>Credit Card</div>
            <div class="text-neutral-60">Visa</div>
          </td>
          <td class="text-left">
            <div>txn_169xRMUPwwaVhEB1U</div>
          </td>
          <td>
            <div class="font-semibold">800</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Invoice Item Line Table - End-->

  <!-- Notes & Calculation Summary - Start -->
  <div class="grid">
    <div class="col-6">
      <div class="font-medium">Notes</div>
      <div class="font-medium text-neutral-60 mb-1">ملاحظات</div>
      <div>-</div>
    </div>
    <div class="col-6">
      <table class="print-table print-item-table">
        <tbody>
          <tr>
            <td>
              <div class="font-medium">Invoice Total</div>
              <div class="font-medium text-neutral-60">
                إجمالي الفاتورة شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>2300</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Collected Amount</div>
              <div class="font-medium text-neutral-60">
                إجمالي المبالغ المحصّلة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>-2000</div>
            </td>
          </tr>
          <tr class="fs-7 text-neutral-60">
            <td class="pt-0">
              <div>Total Amount Paid</div>
              <div class="font-medium">إجمالي المبالغ المدفوعة</div>
            </td>
            <td class="pt-0">
              <div>SAR</div>
              <div>ر.س</div>
            </td>
            <td class="pt-0">
              <div>1800</div>
            </td>
          </tr>
          <tr class="fs-7 text-neutral-60">
            <td class="pt-0">
              <div>Customer Credit</div>
              <div>رصيد العميل</div>
            </td>
            <td class="pt-0">
              <div>SAR</div>
              <div>ر.س</div>
            </td>
            <td class="pt-0">
              <div>200</div>
            </td>
          </tr>
          <tr class="border-top-1 border-neutral-20">
            <td>
              <div class="font-medium">Total Due</div>
              <div class="font-medium text-neutral-60">إجمالي المستحق</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-semibold">300</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Excess Balance</div>
              <div class="font-medium text-neutral-60">رصيد زائد</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-medium">0</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Notes & Calculation Summary - End -->

  @if (invoice?.dueAmount === 0) {
    <!-- Highlight Paid - Start -->
    <div class="flex gap-4 pt-2 justify-content-between">
      <div>
        <div class="font-medium">Payment Status</div>
        <div class="font-medium text-neutral-60">حالة الدفع</div>
        <div class="text-green-60 rw__fs-9 py-2">
          Paid In Full مدفوعة بالكامل
        </div>
      </div>
      <div class="text-right">
        <div class="font-medium">Total Due</div>
        <div class="font-medium text-neutral-60">إجمالي المستحق</div>
        <div class="flex gap-1 align-items-center justify-content-end">
          <div class="line-height-1">
            <div class="rw__fs-9">SAR</div>
            <div class="rw__fs-11 text-neutral-60">ر.س</div>
          </div>
          <div class="rw__fs-5">00</div>
        </div>
      </div>
    </div>
    <!-- Highlight Paid - End -->
  } @else {
    <!-- Highlight Unpaid - Start -->
    <div class="flex gap-4 pt-2 justify-content-between">
      <div>
        <div class="font-medium">Payment Status</div>
        <div class="font-medium text-neutral-60">حالة الدفع</div>
        <div class="text-red-60 rw__fs-9 py-2">Partially Paid مدفوع جزئياً</div>
      </div>
      <div class="text-right">
        <div class="font-medium">Total Due</div>
        <div class="font-medium text-neutral-60">إجمالي المستحق</div>
        <div
          class="flex gap-1 align-items-center justify-content-end text-red-60"
        >
          <div class="line-height-1">
            <div class="rw__fs-9">SAR</div>
            <div class="rw__fs-11">ر.س</div>
          </div>
          <div class="rw__fs-5">{{ invoice?.dueAmount }}</div>
        </div>
      </div>
    </div>
    <!-- Highlight Unpaid - End -->
  }

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9">
        <img
          src="/assets/images/invoice-pdf-qr.svg"
          alt=""
        />
        <div class="mt-2">
          This QR code is encoded as per ZATCA's invoicing requirements
        </div>
        <div>
          رمز الإستجابة السريعة مشفّر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
          للفوترة الإلكترونية
        </div>
      </div>
      <div class="col-3">
        <div class="text-right">Page 1 of 1 - PS-000100</div>
      </div>
    </div>
  </footer>
  <!-- Footer - End -->
</div>
<!-- Payment Page - End -->
