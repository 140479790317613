import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { Router } from '@angular/router';
import {
  CreditNoteLineItemDTO,
  CreditNoteRequest,
  IssueCreditNoteDTO,
  MappedBillingDocument,
} from '../../../../accounts/types';
import { AccountService } from '../../../../accounts/account.service';
import { CustomToastService } from '../../../services/custom-toast.service';
import { CalculatorService } from '../../../services/calculator.service';
import { InvoiceZatcaStatusConstant } from '../../../types';

@Component({
  selector: 'rwa-issue-credit-note',
  templateUrl: './issue-credit-note.component.html',
  styleUrl: './issue-credit-note.component.scss',
})
export class IssueCreditNoteComponent implements OnInit, OnChanges {
  constructor(
    readonly accountService: AccountService,
    readonly customToastService: CustomToastService,
    readonly router: Router,
  ) {}

  @Input() issueCreditNoteInput: {
    dialogVisible: boolean;
    email: string;
    subscriptionId: string;
    invoiceData: MappedBillingDocument;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() closeDialog = new EventEmitter<any>();

  @Output() issueCreditNoteSuccess = new EventEmitter<void>();

  formGroup!: FormGroup;

  minDate: Date = new Date();

  issuingCreditNote: boolean = false;

  readonly itemsChecked: boolean = true;

  invoiceData: IssueCreditNoteDTO = {
    subtotal: 0,
    discount: 0,
    promotions: 0,
    tax: 0,
    total: 0,
  };

  productList: CreditNoteLineItemDTO[] = [];

  refundOptions: SelectItem[] = [
    { label: 'Full Refund', value: 'full' },
    { label: 'Partial Refund', value: 'partial', disabled: true },
  ];

  dropdownItems: SelectItem[] = [
    { label: 'New Subscription Refund', value: 'New Subscription Refund' },
    { label: 'Renew Subscription Refund', value: 'Renew Subscription Refund' },
    { label: 'New Invoice Cancellation', value: 'New Invoice Cancellation' },
    {
      label: 'Renew Invoice Cancellation',
      value: 'Renew Invoice Cancellation',
    },
    { label: 'Not Paid', value: 'Not Paid' },
    { label: 'Order Change', value: 'Order Change' },
    { label: 'Order Cancellation', value: 'Order Cancellation' },
    { label: 'Retrieve Device', value: 'Retrieve Device' },
  ];

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.issueCreditNoteInput &&
      changes.issueCreditNoteInput.currentValue.dialogVisible === true
    ) {
      this.handleDropdownState();
      this.setProductList();
      this.setInvoiceData();
    }
  }

  initializeForm(): void {
    this.formGroup = new FormGroup({
      refundValue: new FormControl('full'),
      issueDate: new FormControl(null, Validators.required),
      notes: new FormControl(null, Validators.maxLength(500)),
      refundReason: new FormControl(null, Validators.required),
    });
  }

  public get refundValue(): string {
    return this.formGroup.get('refundValue')?.value;
  }

  public get issueDate(): string {
    return this.formGroup.get('issueDate')?.value;
  }

  public get notes(): AbstractControl {
    return this.formGroup.get('notes');
  }

  public get refundReason(): string {
    return this.formGroup.get('refundReason')?.value;
  }

  handleDropdownState(): void {
    if (
      this.issueCreditNoteInput.invoiceData.zatcaStatus ===
      InvoiceZatcaStatusConstant.Rejected
    ) {
      // Disable dropdown and set default value
      this.dropdownItems.push({
        label: 'ZATCA Failure',
        value: 'ZATCA Failure',
      });
      this.formGroup.get('refundReason')?.setValue('ZATCA Failure'); // Default value
      this.formGroup.get('refundReason')?.disable();
    } else {
      this.formGroup.get('refundReason')?.enable();
    }
  }

  issueCreditNote(): void {
    const { id, lineItems } = this.issueCreditNoteInput.invoiceData;
    const plan = lineItems.find((item) => item.type === 'plan');
    const payload: CreditNoteRequest = {
      reference_invoice_id: id,
      plan_id: plan?.id,
      date: Math.floor(new Date(this.issueDate).getTime() / 1000),
      create_reason_code: this.refundReason,
      customer_notes: this.notes.value,
    };

    this.issuingCreditNote = true;
    this.accountService.issueCreditNote(payload).subscribe({
      next: () => {
        this.close();
        this.routeToAccountList(plan?.id);
      },
      error: (err) => {
        this.issuingCreditNote = false;
        this.customToastService.error(err.message);
      },
    });
  }

  setProductList(): void {
    this.productList = this.issueCreditNoteInput.invoiceData.lineItems.map(
      (item) => ({
        name: item.name,
        quantity: item.quantity,
        adjustedPrice: CalculatorService.divide(
          item.unitAmount + item.taxAmount,
          100,
        ),
        totalPrice: CalculatorService.divide(
          item.unitAmount + item.taxAmount,
          100,
        ),
      }),
    );
  }

  setInvoiceData(): void {
    this.invoiceData = {
      subtotal: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.subTotal,
        100,
      ),
      discount: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.discount,
        100,
      ),
      promotions: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.creditsApplied,
        100,
      ),
      tax: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.tax,
        100,
      ),
      total: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.total,
        100,
      ),
    };
  }

  routeToAccountList(planId?: string): void {
    const { email, subscriptionId } = this.issueCreditNoteInput;
    this.router.navigate(['/accounts/list'], {
      state: {
        email,
        subscriptionId,
        popupHeadingMsg: 'Credit Note Issued Successfully',
        popupMsg: 'You can see the credit note in the billing history',
        planWarning: !!planId,
      },
    });
  }

  getRefundNoteValue(): string {
    return this.formGroup.get('notes')?.value || '';
  }

  handleSelectChange(event: SelectButtonChangeEvent): void {
    const selectedValue = event.value;
    if (selectedValue !== 'full') {
      this.formGroup.controls.refundValue.setValue('full');
    }
  }

  close(): void {
    this.formGroup.reset();
    this.formGroup.controls.refundValue.setValue('full');
    this.closeDialog.emit();
  }
}
