import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MappedBillingDocument } from '../../../../accounts/types';
import { AccountService } from '../../../../accounts/account.service';
import { PartialDocument, ReSubmitToZatcaInput } from '../../../types';
import { CustomToastService } from '../../../services/custom-toast.service';

@Component({
  selector: 'rwa-re-submit-to-zatca',
  templateUrl: './re-submit-to-zatca.component.html',
  styleUrl: './re-submit-to-zatca.component.scss',
})
export class ReSubmitToZatcaComponent implements OnChanges {
  constructor(
    private readonly accountService: AccountService,
    private customToastService: CustomToastService,
  ) {}

  @Input({ required: true }) reSubmitToZatcaInput: ReSubmitToZatcaInput;

  loading = false;

  invoice: MappedBillingDocument;

  showSpinner = false;

  isPrintEnabled = false;

  reSubmitLabel: string = 'Re-submit Invoice';

  documentType: string;

  completed = false;

  zatcaErrors: string[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() closeDialog = new EventEmitter<any>();

  @Output() routeToAccountDetails = new EventEmitter<string | undefined>();

  @Output() print = new EventEmitter<MappedBillingDocument>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.reSubmitToZatcaInput &&
      changes.reSubmitToZatcaInput.currentValue.dialogVisible === true
    ) {
      this.showErrorsIfAny();
      this.setResubmitLabel();
    }
  }

  handlePrint(): void {
    this.closeDialog.emit();
    if (this.reSubmitToZatcaInput.wholeInvoice)
      this.print.emit(
        this.reSubmitToZatcaInput.document as MappedBillingDocument,
      );
  }

  handleRouteToAccountDetails(): void {
    this.closeDialog.emit();
    if (this.reSubmitToZatcaInput.wholeInvoice)
      this.routeToAccountDetails.emit();
    else
      this.routeToAccountDetails.emit(
        (this.reSubmitToZatcaInput.document as PartialDocument).email,
      );
  }

  setResubmitLabel(): void {
    if (this.reSubmitToZatcaInput.wholeInvoice)
      this.invoice = {
        ...(this.reSubmitToZatcaInput.document as MappedBillingDocument),
      };
    const type = this.reSubmitToZatcaInput.wholeInvoice
      ? (this.reSubmitToZatcaInput.document as MappedBillingDocument).type
      : (this.reSubmitToZatcaInput.document as PartialDocument).type;
    this.documentType = type === 'invoice' ? 'Invoice' : 'Credit Note';
    this.reSubmitLabel =
      type === 'invoice' ? 'Re-submit Invoice' : 'Re-submit Credit Note';
  }

  reSubmitToZatca(): void {
    this.loading = true;
    const payload = this.reSubmitToZatcaInput.wholeInvoice
      ? {
          id: (this.reSubmitToZatcaInput.document as MappedBillingDocument).id,
          type: (this.reSubmitToZatcaInput.document as MappedBillingDocument)
            .type,
        }
      : {
          id: (this.reSubmitToZatcaInput.document as PartialDocument).id,
          type: (this.reSubmitToZatcaInput.document as PartialDocument).type,
        };

    this.accountService.retryInvoicesOrCreditNotes(payload).subscribe({
      next: () => {
        this.loading = false;
        this.completed = true;
      },
      complete: () => {
        this.loading = false;
        this.completed = true;
      },
      error: (err) => {
        this.customToastService.error(err.error.message);
        this.completed = false;
        this.loading = false;
        this.closeDialog.emit();
      },
    });
  }

  showErrorsIfAny(): void {
    this.zatcaErrors = this.reSubmitToZatcaInput.document.zatcaError?.map(
      (message) => message.message,
    );
  }

  close(): void {
    this.closeDialog.emit();
  }
}
